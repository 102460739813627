<template>
    <v-container>
      <page-title-block :store-name="storeName" page-name="Store Details"/>
      <v-row v-if="showGraphs">
        <v-col cols="12">
          <store-history-graphv2
            :store-id="storeId"
          ></store-history-graphv2>
        </v-col>
      </v-row>
      <v-row>
        <v-col align="center">
          <v-btn-toggle
            v-model="showGraphs">
            <v-btn
              :value="true"
              elevation="5"
              color="primary lighten-3"
            >
              <span v-if="showGraphs">Hide </span><span v-else>Show </span><span class="ml-1">Graphs</span>
              <v-icon right dark>mdi-finance</v-icon>
            </v-btn>
          </v-btn-toggle>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3" sm="6">
          <StatisticCard
            title="Pick Queue"
            :statistic="storeDetails.pickQueue"
            units="orders"
          ></StatisticCard>
        </v-col>
        <v-col cols="12" md="2" sm="6">
          <StatisticCard
            title="Orders Today"
            :statistic="storeDetails.stats && storeDetails.stats.consignmentCreation ? storeDetails.stats.consignmentCreation : 0"
            units="orders"
          ></StatisticCard>
        </v-col>
        <v-col cols="12" md="2" sm="6">
          <StatisticCard
            title="Picked Today"
            :statistic="storeDetails.picks"
            units="orders"
          ></StatisticCard>
        </v-col>
        <v-col cols="12" md="2" sm="6">
          <StatisticCard
            title="Collected Today"
            :statistic="storeDetails.collections"
            units="orders"
          ></StatisticCard>
        </v-col>
        <v-col cols="12" md="3" sm="6">
          <StatisticCard
            title="C&C Store Status"
            :statistic="storeDetails.onlineStatus ? 'Online' : 'Offline'"
            :subtitle="storeDetails.lastOnlineStatusChange | offlineSince"
            :colour="storeDetails.onlineStatus ? '' : 'red--text'"
          ></StatisticCard>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3" sm="6">
          <StatisticCard
            title="Offline Threshold"
            :statistic="storeDetails.upperThreshold"
            units="orders"
          ></StatisticCard>
        </v-col>
        <v-col cols="12" md="3" sm="6">
          <StatisticCard
            title="Online Threshold"
            :statistic="storeDetails.lowerThreshold"
            units="orders"
          ></StatisticCard>
        </v-col>
        <v-col cols="12" md="3" sm="6">
          <StatisticCard
            v-if="storeDetails.onlineStatus"
            title="Store Offline In"
            :statistic="storeDetails.upperThreshold - storeDetails.pickQueue"
            units="orders"
            subtitle="Customers will not be able to place orders until the pick queue is reduced below the lower threshold"
          ></StatisticCard>
          <StatisticCard
            v-else
            title="Store Back Online In"
            :statistic="storeDetails.pickQueue - storeDetails.lowerThreshold"
            units="picks"
            subtitle="The number of orders that need to be picked before the website will allow C&C orders again"
          ></StatisticCard>
        </v-col>
        <v-col cols="12" md="3" sm="6">
          <StatisticCard
            title="Time Spent Offline"
            :statistic="actualTimeSpentOffline | offlineTimeFormatter"
            :units="actualTimeSpentOffline | offlineTimeFormatter(true)"
            subtitle="Since midnight"
          ></StatisticCard>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">CATS Statistics</v-col>
      </v-row>
      <v-row v-if="storeDetails.stats">
        <v-col cols="12" md="3" sm="6">
          <StatisticCard
            title="Arrival Time Set"
            :statistic="storeDetails.stats && storeDetails.stats.arrivalTimeSet ? storeDetails.stats.arrivalTimeSet : 0"
            units="customers"
          ></StatisticCard>
        </v-col>
        <v-col cols="12" md="3" sm="6">
          <StatisticCard
            title="Click & Parks"
            :statistic="storeDetails.stats && storeDetails.stats.clickAndParkRequested ? storeDetails.stats.clickAndParkRequested : 0"
            :min="storeDetails.stats && storeDetails.stats.clickAndParkAccepted ? storeDetails.stats.clickAndParkAccepted : 0"
            min-text="Accepted"
            :max="storeDetails.stats && storeDetails.stats.clickAndParkRejected ? storeDetails.stats.clickAndParkRejected : 0"
            max-text="Rejected"
            units="cars"
          ></StatisticCard>
        </v-col>
        <v-col cols="12" md="3" sm="6">
          <StatisticCard
            title="Customer Departures"
            :statistic="storeDetails.stats && storeDetails.stats.customerArrived ? storeDetails.stats.customerArrived : 0"
            units="customers"
          ></StatisticCard>
        </v-col>
        <v-col cols="12" md="3" sm="6">
          <StatisticCard
            title="Average Pick Time"
            :statistic="storeDetails.stats && storeDetails.stats.pickTime ? storeDetails.stats.pickTime : 0 | round2DP"
            units="mins"
            :min="storeDetails.stats && storeDetails.stats.pickTimeMin ? storeDetails.stats.pickTimeMin : 0 | round2DP"
            :max="storeDetails.stats && storeDetails.stats.pickTimeMax ? storeDetails.stats.pickTimeMax : 0 | round2DP"
          ></StatisticCard>
        </v-col>

        <v-col cols="12" md="3" sm="6">
          <StatisticCard
            title="C&P Accept Times"
            :statistic="storeDetails.stats && storeDetails.stats.cnpAcceptResponseTime ? storeDetails.stats.cnpAcceptResponseTime : 0 | round2DP"
            units="seconds"
            :min="storeDetails.stats && storeDetails.stats.cnpAcceptResponseTimeMin ? storeDetails.stats.cnpAcceptResponseTimeMin : 0 | round2DP"
            :max="storeDetails.stats && storeDetails.stats.cnpAcceptResponseTimeMax ? storeDetails.stats.cnpAcceptResponseTimeMax : 0 | round2DP"
          ></StatisticCard>
        </v-col>
        <v-col cols="12" md="3" sm="6">
          <StatisticCard
            title="C&P Reject Times"
            :statistic="storeDetails.stats && storeDetails.stats.cnpRejectResponseTime ? storeDetails.stats.cnpRejectResponseTime : 0 | round2DP"
            units="seconds"
            :min="storeDetails.stats && storeDetails.stats.cnpRejectResponseTimeMin ? storeDetails.stats.cnpRejectResponseTimeMin : 0 | round2DP"
            :max="storeDetails.stats && storeDetails.stats.cnpRejectResponseTimeMax ? storeDetails.stats.cnpRejectResponseTimeMax : 0 | round2DP"
          ></StatisticCard>
        </v-col>
        <v-col cols="12" md="3" sm="6">
          <StatisticCard
            title="C&P Response Rate"
            :statistic="candpPercentage | round2DP"
            units="%"
          ></StatisticCard>
        </v-col>

      </v-row>
    </v-container>
</template>

<script>
import { mapState } from 'vuex'
import StatisticCard from '../components/StatisticCard'
import moment from 'moment-timezone'
import StoreHistoryGraphv2 from '../components/StoreHistoryGraphv2'
import PageTitleBlock from '@/components/PageTitleBlock'

export default {
  name: 'StoreDetailsView',
  components: { PageTitleBlock, StoreHistoryGraphv2, StatisticCard },
  filters: {
    offlineTimeFormatter: (time, suffix) => {
      const timeArray = moment.duration(time, 'minutes').humanize().split(' ')
      return suffix ? timeArray.slice(1).join(' ') : timeArray[0]
    },
    menuSummariser: (text) => {
      return text ? text.split(' ').map(word => word.replace(/[aeiou]/ig, '')).map(word => word.slice(0, 3)).join(' ') : ''
    },
    offlineSince: (value) => {
      if (!value) return ''
      const time = moment(value)
      if (time.dayOfYear() === moment().dayOfYear()) {
        return 'Since ' + time.format('HH:mm')
      } else {
        return 'Since ' + time.format('ddd Do MMM, HH:mm')
      }
    },
    round2DP: (value) => Number(value).toFixed(1)
  },
  data () {
    return {
      loading: false,
      error: null,
      showGraphs: false,
      timer: '',
      topMenuButtons: [
        {
          to: `/${this.$route.params.storeId}/dashboard`,
          text: 'Dashboard'
        },
        {
          to: `/${this.$route.params.storeId}/store-details`,
          text: 'Store Details'
        }
      ]
    }
  },
  computed: {
    ...mapState('instore', ['stores', 'storeDetails']),
    storeId: function () {
      return this.$route.params.storeId
    },
    storeName: function () {
      const storeDetails = this.stores.find(item => item.storeId === this.storeId)
      return storeDetails ? storeDetails.storeName : 'Unknown'
    },
    actualTimeSpentOffline: function () {
      if (this.storeDetails.onlineStatus === true) {
        return this.storeDetails.timeOffline
      } else {
        // the store is offline, so calculate the time it went offline and add the hours since then
        const lastChangeWasToday = moment(this.storeDetails.lastOnlineStatusChange).isSame(moment(), 'day')
        const timeWentOffline = lastChangeWasToday ? moment(this.storeDetails.lastOnlineStatusChange) : moment.tz('Europe/London').startOf('day')
        const timeSinceLastChange = moment.tz('Europe/London').diff(timeWentOffline, 'minutes')
        return this.storeDetails.timeOffline + timeSinceLastChange
      }
    },
    candpPercentage: function () {
      const fraction = ((this.storeDetails.stats && this.storeDetails.stats.clickAndParkAccepted ? this.storeDetails.stats.clickAndParkAccepted : 0) + (this.storeDetails.stats && this.storeDetails.stats.clickAndParkRejected ? this.storeDetails.stats.clickAndParkRejected : 0)) /
              Math.max((this.storeDetails.stats && this.storeDetails.stats.clickAndParkRequested ? this.storeDetails.stats.clickAndParkRequested : 0), 1) // prevent div by 0
      return fraction * 100
    }
  },
  created () {
    this.fetchData()
    this.timer = setInterval(this.fetchData, 30000)
  },
  watch: {
    // call again the method if the route changes
    $route: 'fetchData'
  },
  methods: {
    fetchData: async function () {
      this.error = null
      this.loading = true
      const token = await this.$auth.getTokenSilently()
      this.$store.dispatch('instore/loadStoreDetails', { storeId: this.storeId, token })
        .then(() => (this.loading = false))
        .catch(err => (this.error = err))
    }
  },
  beforeDestroy () {
    clearInterval(this.timer)
  }
}
</script>

<style scoped>

</style>
